import { template as template_0cc2963e2e6d412ba030a790814f5cab } from "@ember/template-compiler";
const SidebarSectionMessage = template_0cc2963e2e6d412ba030a790814f5cab(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
