import { template as template_d1423db93e4549088d60a9a097df18bf } from "@ember/template-compiler";
const FKControlMenuContainer = template_d1423db93e4549088d60a9a097df18bf(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
