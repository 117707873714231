import { template as template_d920a1c4f28641eca0c581e5b7813806 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_d920a1c4f28641eca0c581e5b7813806(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
